<template>
  <b-alert show :variant="type" class="custom-badge-component ">
    {{ text }}</b-alert
  >
</template>

<script>
export default {
  props: {
    type: { default: 'warning' },
    text: {
      default:
        'Ce client a déjà des règlements non totalement lettrés, veuillez les utiliser'
    }
  }
};
</script>

<style lang="scss">
.custom-badge-component {
  font-family: 'Montserrat';
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
